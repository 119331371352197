<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrderList',
              }"
            >訂單管理
            </b-breadcrumb-item
            >
            <b-breadcrumb-item active>訂單資料</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row mb-3 align-items-center">
          <div class="col-12 col-xl-2">
            <h4 class="font-weight-bold">訂單內容</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-4 mb-2">分店名稱&ID：{{ order.branch ? order.branch.name + order.branch.id : '' }}</div>
        </div>
        <div class="row">
          <div class="col-4">
            <b-form-group :disabled=readonly>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="訂單編號(內部)"
              >
                <b-form-input v-model="order.order_number"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="外部訂單編號"
              >
                <b-form-input v-model="order.outer_order_number"></b-form-input>
              </b-form-group>
              <div>客戶會員姓名&ID：{{ order.customer ? order.customer.name + order.customer.id : '' }}</div>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="外部會員ID"
              >
                <b-form-input v-model="order.customer_outer_code"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="訂單狀態"
              >
                <b-form-select v-model="order.status" :options="orderStatusOptions"></b-form-select>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="付款方式"
              >
                <b-form-input v-model="order.payment_type"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="配送方式"
              >
                <b-form-input v-model="order.delivery_type"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="貨運單配送編號"
              >
                <b-form-input v-model="order.shipping_code"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="付款狀態"
              >
                <b-form-input v-model="order.payment_status"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="運送狀態"
              >
                <b-form-input v-model="order.delivery_status"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="消費者備註狀態"
              >
                <b-form-input v-model="order.memo"></b-form-input>
              </b-form-group>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group :disabled=readonly>
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="l"
                content-cols="12"
                content-cols-lg="8"
                label="下單時間"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      v-model="order.order_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                    ></datepicker>
                  </div>
                  <div class="ml-2 w-50">
                    <vue-timepicker
                      v-if="order.order_at.time"
                      format="HH:mm"
                      v-model="order.order_at.time"
                      :input-class="['form-control']"
                      :hide-clear-button="true"
                    ></vue-timepicker>
                    <b-form-input v-else></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="l"
                content-cols="12"
                content-cols-lg="8"
                label="訂單狀態日"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      v-model="order.status_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                    ></datepicker>
                  </div>
                  <div class="ml-2 w-50">
                    <vue-timepicker
                      v-if="order.status_at.time"
                      format="HH:mm"
                      v-model="order.status_at.time"
                      :input-class="['form-control']"
                      :hide-clear-button="true"
                    ></vue-timepicker>
                    <b-form-input v-else></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="l"
                content-cols="12"
                content-cols-lg="8"
                label="訂單確認日期"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      v-model="order.confirm_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                    ></datepicker>
                  </div>
                  <div class="ml-2 w-50">
                    <vue-timepicker
                      v-if="order.status_at.time"
                      format="HH:mm"
                      v-model="order.confirm_at.time"
                      :input-class="['form-control']"
                      :hide-clear-button="true"
                    ></vue-timepicker>
                    <b-form-input v-else></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="l"
                content-cols="12"
                content-cols-lg="8"
                label="完成時間"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      v-model="order.completed_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                    ></datepicker>
                  </div>
                  <div class="ml-2 w-50">
                    <vue-timepicker
                      v-if="order.completed_at.time"
                      format="HH:mm"
                      v-model="order.completed_at.time"
                      :input-class="['form-control']"
                      :hide-clear-button="true"
                    ></vue-timepicker>
                    <b-form-input v-else></b-form-input>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="l"
                content-cols="12"
                content-cols-lg="8"
                label="建立時間"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="order.created_at"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :disabled=true
                    ></datepicker>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="l"
                content-cols="12"
                content-cols-lg="8"
                label="更新時間"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="order.updated_at"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :disabled=true
                    ></datepicker>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="金額小計"
              >
                <b-form-input v-model="order.sub_total"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="訂單折扣"
              >
                <b-form-input v-model="order.order_discount"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="運費"
              >
                <b-form-input v-model="order.delivery_fee"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="金流費"
              >
                <b-form-input v-model="order.payment_fee"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="總價(含稅)"
              >
                <b-form-input v-model="order.total"></b-form-input>
              </b-form-group>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-4 align-items-center">
          <div class="col-12 col-xl-2">
            <h4 class="font-weight-bold">訂單明細</h4>
          </div>
        </div>
        <div class="text-right" v-if="!readonly">
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            @click="addItemHandler"
          ><i class="fa fa-plus" aria-hidden="true"></i
          >新增商品
          </b-button
          >
        </div>
        <b-table
          striped
          hover
          :items="order.items"
          :fields="fields"
          :busy="showLoading"
        >
          <template #cell(name)="data">
            <div v-if="readonly">
              {{ data.item.sku_name }}
              {{ data.item.sku_code }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="3"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6"
                label="商品名稱"
              >
                <b-form-input v-model="data.item.sku_name"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-sm="3"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6"
                label="商品編號"
              >
                <b-form-input v-model="data.item.sku_code"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(price)="data">
            <div v-if="readonly">
              {{ data.item.price }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6">
                <b-form-input type="number" v-model="data.item.price"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(quantity)="data">
            <div v-if="readonly">
              {{ data.item.quantity }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6">
                <b-form-input type="number" v-model="data.item.quantity"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(total_price)="data">
            <div v-if="readonly">
              {{ data.item.total_price }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6">
                <b-form-input type="number" v-model="data.item.total_price"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(total_discount)="data">
            <div v-if="readonly">
              {{ data.item.total_discount }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6">
                <b-form-input type="number" v-model="data.item.total_discount"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(total_payment)="data">
            <div v-if="readonly">
              {{ data.item.total_payment }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6">
                <b-form-input type="number" v-model="data.item.total_payment"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(status)="data">
            <div v-if="readonly">
              {{ data.item.status }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6"
                label="訂單狀態">
                <b-form-select v-model="data.item.status" :options="orderStatusOptions"></b-form-select>
              </b-form-group>
            </div>
            <div>
              {{ format(new Date(data.item.status_at), "yyyy-MM-dd HH:mm") }}
            </div>
            <div>
              {{ format(new Date(data.item.confirm_at), "yyyy-MM-dd HH:mm") }}
            </div>
          </template>
          <template #cell(delivery)="data">
            <div v-if="readonly">
              {{ data.item.delivery_status }}
              {{ data.item.delivery_type }}
              {{ data.item.delivery_shipping_code }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6"
                label="配送狀態">
                <b-form-input v-model="data.item.delivery_status"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6"
                label="配送方式">
                <b-form-input v-model="data.item.delivery_type"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6"
                label="貨運單配送編號">
                <b-form-input v-model="data.item.delivery_shipping_code"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(return)="data">
            <div v-if="readonly" style="color: red">
              {{ data.item.return_shipping_code }}
              {{ data.item.return_shipping_id }}
              {{ data.item.return_status }}
            </div>
            <div v-else style="color: red">
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6"
                label="退貨運單配送編號">
                <b-form-input v-model="data.item.return_shipping_code"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6"
                label="退貨運單配送id">
                <b-form-input v-model="data.item.return_shipping_id"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="6"
                content-cols-sm="6"
                label="退貨狀態">
                <b-form-input v-model="data.item.return_status"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(arrange)="data" v-if="!readonly">
            <b-button class="delete-button" variant="danger" @click="removeItemHandler(data.index)">刪除商品</b-button>
          </template>
        </b-table>
        <div class="d-flex justify-content-center" v-if="!readonly" style="margin-top: 80px">
          <b-button class="mr-4" variant="outline-danger" @click="handleCancel">
            <template>返回</template>
          </b-button>
          <b-button variant="success" @click="handleSubmit">
            <template v-if="isEdit">確認編輯</template>
            <template v-if="isCreate">確認新增</template>
          </b-button>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {format, set, formatISO} from "date-fns";
import orderApi from "../../../apis/order";
import {mapState} from "vuex";
import Datepicker from "vuejs-datepicker";
import {zh} from "vuejs-datepicker/dist/locale";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import VueTimepicker from "vue2-timepicker";

export default {
  components: {Datepicker, VueTimepicker},
  data() {
    return {
      zh,
      showLoading: false,
      order: {
        order_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        status_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        confirm_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        completed_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        items: [],
      },
      fields: [
        {key: "outer_order_item_number", label: "訂單明細編號",},
        {key: "title", label: "商品標題",},
        {key: "sku_name", label: "sku 名稱",},
        {key: "sku_code", label: "sku code",},
        {key: "sku_serial_number", label: "sku serial number",},
        {key: "price", label: "售價",},
        {key: "quantity", label: "數量",},
        {key: "total_price", label: "小計",},
        {key: "processed_memo", label: "處理情況",},
      ],
      orderStatusOptions: [
        {value: null, text: '請選擇狀態'},
        {value: 'Confirmed', text: '已成立'},
        {value: 'Finish', text: '已完成'},
        {value: 'Cancel', text: '已取消'},
        {value: 'WaitingToShipping', text: '出貨準備中'},
        {value: 'WaitingToCreditCheck', text: '付款確認中'},
        {value: 'ConfirmedToShipping', text: '已確認待出貨'},
        {value: 'WaitingToPay', text: '待付款'},
        {value: 'WaitingToModifyStore', text: '門市閉店，待消費者重選'},
      ]
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    readonly() {
      return this.$route.name === "OrderDetail"
    },
    isEdit() {
      return this.$route.name === "OrderEdit"
    },
    isCreate() {
      return this.$route.name === "OrderCreate"
    }
  },
  mounted() {
    if (!this.isCreate) {
      this.fetchOrder()
    }
    if (!this.readonly) {
      this.fields.push({key: "arrange", label: "管理",})
    }
    if (this.isCreate && this.$route.query.merchant_id) {
      this.order.merchant_id = this.$route.query.merchant_id
    }
  },
  methods: {
    format,
    formatToDateObject(datetime) {
      if (!datetime) {
        return {}
      }
      return {
        date: new Date(datetime),
        time: {
          HH: String(getHours(new Date(datetime))).padStart(2, "0"),
          mm: String(getMinutes(new Date(datetime))).padStart(2, "0"),
        },
      }
    },
    async fetchOrder() {
      this.showLoading = true;

      try {
        const {data} = await orderApi.getOrder(this.$route.params.order_id);
        this.order = data.data;

        this.order.order_at = this.formatToDateObject(this.order.order_at)
        this.order.status_at = this.formatToDateObject(this.order.status_at)
        this.order.confirm_at = this.formatToDateObject(this.order.confirm_at)
        this.order.completed_at = this.formatToDateObject(this.order.completed_at)

      } catch (error) {
        console.error(error);
        alert("取得群組資料錯誤");
      }
      this.showLoading = false;
    },
    handleCancel() {
      this.$router.push({name: "OrderList"});
    },
    formatToDatetimeString(datetime) {
      return formatISO(
        set(new Date(datetime.date), {
            hours: Number(datetime.time.HH),
            minutes: Number(datetime.time.mm),
            seconds: 0,
          },
        )
      )
    },
    async handleSubmit() {
      this.showLoading = true;

      this.order.order_at = this.formatToDatetimeString(this.order.order_at)
      this.order.status_at = this.formatToDatetimeString(this.order.status_at)
      this.order.confirm_at = this.formatToDatetimeString(this.order.confirm_at)
      this.order.completed_at = this.formatToDatetimeString(this.order.completed_at)

      if (this.isEdit) {
        await this.goUpdate()
      }
      if (this.isCreate) {
        await this.goCreate()
      }

      this.showLoading = false;
    },
    async goUpdate() {
      try {
        await orderApi.updateOrder(this.order.id, this.order);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({name: "OrderList"});
          });
      } catch (error) {
        console.error(error);
      }
    },
    async goCreate() {
      try {
        await orderApi.createOrder(this.order);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            const params = {name: "OrderList"};
            if (this.$route.query.merchant_id) params.query = {merchant_id: this.$route.query.merchant_id}
            this.$router.push(params);
          });
      } catch (error) {
        console.error(error);
      }
    },
    addItemHandler() {
      this.order.items.push({
        id: null,
        sku_name: null,
        sku_code: null,
        price: 0,
        quantity: 0,
        total_discount: 0,
        status: null,
        status_at: null,
        confirm_at: null,
        delivery_status: null,
        delivery_type: null,
        delivery_shipping_code: null,
        return_shipping_code: null,
        return_shipping_id: null,
        return_status: null,
      })
    },
    removeItemHandler(index) {
      this.order.items = this.order.items.filter((item, i) => i !== index);
      this.$emit('input', this.order);
    },
  },
};
</script>
